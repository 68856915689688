import React, { useCallback, useRef } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import {
	FormProvider,
	useForm,
	UseFormOptions,
	useFormContext,
	UseFormMethods,
} from "react-hook-form";
// eslint-disable-next-line import/no-extraneous-dependencies
import * as Yup from "yup";

import useDerivedProps from "hooks/useDerivedStateFromProps";

export type UseFormProps = ReturnType<typeof useForm>;

export interface FormContainerProps<F> {
	useFormFields?: UseFormOptions;
	onSubmit?: (data: F, reset: () => void) => void;
	validationSchema: unknown;
	children: React.ReactNode;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	register?: (methods: UseFormMethods<Record<string, any>>) => void;
}

export function FormContainer<F>({
	register,
	children,
	onSubmit,
	validationSchema,
	...innerProps
}: FormContainerProps<F>) {
	const methods = useForm({
		mode: "all",
		...innerProps,
		resolver: yupResolver(validationSchema as Yup.ObjectSchema),
		criteriaMode: "firstError",
		shouldFocusError: true,
	});

	useDerivedProps(() => {
		if (register) {
			register(methods);
		}
	}, register);

	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<FormProvider {...methods}>
			<form
				onSubmit={
					onSubmit
						? methods.handleSubmit((data) => onSubmit(data as F, methods.reset))
						: () => {}
				}
			>
				{children}
			</form>
		</FormProvider>
	);
}

export const ConnectForm: React.FC = ({ children }) => {
	const methods = useFormContext();

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return (children as any)({ ...methods });
};

export const useFormContainer = () => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const ref = useRef<UseFormMethods<Record<string, any>>>({
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} as UseFormMethods<Record<string, any>>);

	const register = useCallback((method: typeof ref.current) => {
		Object.assign(ref.current, method);
	}, []);

	return {
		...ref.current,
		methodsRef: ref,
		register,
	};
};
