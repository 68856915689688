import {
	combineReducers,
	configureStore,
	getDefaultMiddleware,
} from "@reduxjs/toolkit";
import { createSelectorHook, useDispatch } from "react-redux";
import {
	FLUSH,
	PAUSE,
	PERSIST,
	persistReducer,
	persistStore,
	PURGE,
	REGISTER,
	REHYDRATE,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

import authSlice from "./Auth/reducer";
import storesSlice from "./Stores/reducer";

// @see https://github.com/reduxjs/redux-toolkit/issues/121#issuecomment-480621931
// Create a Persist-Config
const persistConfig = {
	key: "ESW",
	storage,
	whitelist: ["stores"],
	blacklist: [],
};

const rootReducer = combineReducers({
	auth: authSlice.reducer,
	stores: storesSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
	devTools: process.env.NODE_ENV === "development",
	reducer: persistedReducer,
	// @see https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
	middleware: getDefaultMiddleware({
		serializableCheck: {
			ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
		},
	}),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const useAppSelector = createSelectorHook<RootState>();

export const useAppDispatch = () => useDispatch<AppDispatch>();

export const persistor = persistStore(store);

export const getStoreState = store.getState;

export default store;
