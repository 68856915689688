import React, { useMemo } from "react";

import { mapModifiers } from "helpers/component";

export type HeadingType = "h1" | "h2" | "h3" | "h4";

export type Color = "pastel-orange" | "black" | "yellowLinear";

export interface Props {
	type?: HeadingType;
	noBackground?: boolean;
	color?: Color;
}

export const Heading: React.FC<Props> = ({
	type,
	children,
	noBackground,
	color,
}) => {
	const renderHeadingText = useMemo(() => {
		switch (type) {
			case "h1":
				return <h1 className="a-heading_text">{children}</h1>;
			case "h2":
				return <h2 className="a-heading_text">{children}</h2>;
			case "h3":
				return <h3 className="a-heading_text">{children}</h3>;
			case "h4":
				return <h3 className="a-heading_text">{children}</h3>;
			default:
				return null;
		}
	}, [children, type]);

	return (
		<div
			className={mapModifiers(
				"a-heading",
				`type-${type}`,
				noBackground && "noBackground",
				color
			)}
		>
			{renderHeadingText}
		</div>
	);
};

Heading.defaultProps = {
	type: "h2",
};
