import React from "react";

import ReactModal from "react-modal";

import { Heading } from "components/atoms/Heading";
import { Icon } from "components/atoms/Icon";
import { mapModifiers } from "helpers/component";

type Modifiers = "background-transparent" | "nonborder" | "confirm" | "wheels";

export interface Props {
	modifiers?: Modifiers | Array<Modifiers>;
	isOpen: boolean;
	style?: ReactModal.Styles;
	closable?: boolean;
	shouldCloseOnEsc?: boolean;
	shouldCloseOnOverlayClick?: boolean;
	fullBox?: boolean;
	heading?: string;
	footer?: React.ReactNode;
	onCloseModal?: () => void;
}

export const Modal: React.FC<Props> = ({
	modifiers,
	heading,
	isOpen,
	closable,
	style,
	shouldCloseOnEsc,
	shouldCloseOnOverlayClick,
	children,
	fullBox,
	footer,
	onCloseModal,
}) => {
	return (
		<div className="o-modal">
			<ReactModal
				htmlOpenClassName="reactmodal-html-open"
				isOpen={isOpen}
				overlayClassName="o-modal_overlay"
				onRequestClose={onCloseModal}
				portalClassName="o-modal_portal"
				className={mapModifiers(
					"o-modal_content",
					modifiers,
					fullBox && "fullbox",
					heading || footer ? "fixedheaderfooter" : undefined
				)}
				ariaHideApp={false}
				style={style || undefined}
				shouldCloseOnEsc={shouldCloseOnEsc}
				shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
			>
				{closable && !heading && (
					<div className="o-modal_close">
						<Icon iconName="grey-times" onClick={onCloseModal} clickable />
					</div>
				)}
				{heading && (
					<div className="o-modal_header">
						<Heading>{heading}</Heading>
						<Icon iconName="grey-times" onClick={onCloseModal} clickable />
					</div>
				)}
				<div className="o-modal_body">{children}</div>
				{footer && <div className="o-modal_footer">{footer}</div>}
			</ReactModal>
		</div>
	);
};

Modal.defaultProps = {
	closable: true,
	shouldCloseOnEsc: true,
	shouldCloseOnOverlayClick: true,
};
