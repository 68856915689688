import http from "services/http";

import { PrizesExchangePayload } from "./types";

export const apiPrizesExchange = ({
	soBill,
	soQuay,
	drawTurn,
	...others
}: PrizesExchangePayload) =>
	http.post(
		"/customers/prizes/exchange",
		{
			...others,
			so_bill: soBill,
			so_quay: soQuay,
			draw_turn: drawTurn,
		},
		{
			requireAuthentication: true,
		}
	);

export const checkPhoneExchange = (phone: string) =>
	http.get(`/pg/product-exchanged/${phone}`, {
		requireAuthentication: true,
	});
