import { apiLogin, apiLogout } from "services/PG";
import { LoginPayload } from "services/PG/types";
import { initialCustomerSession } from "services/Sessions";
import { useAppDispatch } from "store";
import authSlice from "store/Auth/reducer";
import storesSlice from "store/Stores/reducer";

export const useUserAuthAction = () => {
	const dispatch = useAppDispatch();

	const handleLogout = () =>
		apiLogout().then(() => {
			dispatch(authSlice.actions.logout());
			dispatch(storesSlice.actions.clearStoreInfors());
		});

	const handleLogin = (payload: LoginPayload) =>
		apiLogin(payload)
			.then((res) => ({
				token: res?.data?.data?.token,
				user: res?.data?.data?.model,
			}))
			.then(({ token, user }) => {
				dispatch(
					authSlice.actions.updateUserAuthState({
						user,
						token,
					})
				);
				initialCustomerSession({
					accessToken: token,
				});
			});

	return { handleLogin, handleLogout };
};
