import React from "react";

import useWindowDimensions from "hooks/useWindowDemensions";

export const Main: React.FC = ({ children }) => {
	const { height } = useWindowDimensions();
	return (
		<div className="o-main" style={{ minHeight: `${height - 64}px` }}>
			{children}
		</div>
	);
};
