/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from "react";

import { Button } from "components/atoms/Button";
import { Heading } from "components/atoms/Heading";
import { Text } from "components/atoms/Text";
import { TextfieldHookForm } from "components/atoms/TextField";
import { toastSingleMode } from "components/atoms/Toastify";
import { FormField } from "components/molecules/FormField";
import { Container } from "components/organisms/Grid";
import { Modal } from "components/organisms/Modal";
import { General } from "components/pages/general";
import { FormContainer, useFormContainer } from "helpers/form";
import { useAsyncAction } from "hooks/useAsyncAction";
import { apiPrizesExchange, checkPhoneExchange } from "services/Exchange";
import { ProductOfBillSelect } from "services/PG/types";

export interface Props {
	noGift?: boolean;
	phone?: string;
	isVerifyOtp: boolean;
	initProduct?: ProductOfBillSelect;
	dupForEnsure?: boolean;
	channel: "MT" | "GT" | "CV" | "CC";
	onSubmit?: (hasDraw: boolean) => void;
	goBack: () => void;
}

const PRODUCT_FORM_FIELD_NAME_TO_LABEL: { [key: string]: string } = {
	EGVani380: "Ensure Gold Vani (HMB) 380g",
	EGVani800: "Ensure Gold Vani (HMB) 800g",
	EGVaniIN380: "Ensure Gold Vani ít ngọt (HMB) 380g",
	EGVaniIN800: "Ensure Gold Vani ít ngọt (HMB) 800g",
	EGLM380: "Ensure Gold Lúa mạch (HMB) 380g",
	EGLM800: "Ensure Gold Lúa mạch (HMB) 800g",
	EGCF380: "Ensure Gold Cà phê (HMB) 380g",
	EGCF800: "Ensure Gold Cà phê (HMB) 800g",
	EGGH380: "Ensure Gold Green Health 380g",
	EGGH800: "Ensure Gold Green Health 800g",
	ELVLoc380: "Ensure Gold dạng nước 237ml (lốc)",
};

const GIFT_FORM_FIELD_NAME_TO_LABEL: { [key: string]: string } = {
	lythuytinh: "Ly thuỷ tinh",
	binhgiunhiet: "Bình giữ nhiệt",
	candientu: "Cân điện tử",
	binhdunsieutoc: "Bình đun siêu tốc",
	mayxaysinhto: "Máy xay sinh tố",
	vali: "Vali",
};

const PRODUCT_FORM_FIELD = [
	// {
	// 	name: "EGVani",
	// 	label: "Ensure Gold Vani (HMB)",
	// 	isMultiple: true,
	// },
	// {
	// 	name: "EGVaniIN",
	// 	label: "Ensure Gold Vani ít ngọt (HMB)",
	// 	isMultiple: true,
	// },
	// {
	// 	name: "EGLM",
	// 	label: "Ensure Gold Lúa mạch (HMB)",
	// 	isMultiple: true,
	// },
	// {
	// 	name: "EGCF",
	// 	label: "Ensure Gold Cà phê (HMB)",
	// 	isMultiple: true,
	// },
	{
		name: "EGVani",
		label: "Ensure Gold Vani (HMB + YBG)",
		isMultiple: true,
	},
	{
		name: "EGVaniIN",
		label: "Ensure Gold Vani ít ngọt (HMB + YBG)",
		isMultiple: true,
	},
	{
		name: "EGLM",
		label: "Ensure Gold Lúa mạch (HMB + YBG)",
		isMultiple: true,
	},
	{
		name: "EGCF",
		label: "Ensure Gold Cà phê (HMB + YBG)",
		isMultiple: true,
	},
	{
		name: "EGGH",
		label: "Ensure Gold Green Health",
		isMultiple: true,
	},
	{
		name: "ELVLoc",
		label: "Ensure Gold dạng nước 237ml (lốc)",
		isMultiple: false,
	},
];

const ENSURE_GIFT_FORM_FIELD = [
	{
		name: "lythuytinh",
		label: "Quà 1 ly thuỷ tinh",
		isGift: true,
		isRead: false,
	},
	{
		name: "binhgiunhiet",
		label: "Quà 1 bình giữ nhiệt",
		isGift: true,
		isRead: false,
	},
	{
		name: "candientu",
		label: "Quà 1 cân điện tử",
		isGift: true,
		isRead: false,
	},
	{
		name: "binhdunsieutoc",
		label: "Quà 1 bình đun siêu tốc",
		isGift: true,
		isRead: false,
	},
	{
		name: "mayxaysinhto",
		label: "Quà 1 máy xay sinh tố",
		isGift: true,
		isRead: false,
	},
	{
		name: "vali",
		label: "Quà 1 vali",
		isGift: true,
		isRead: false,
	},
];

const ENSURE_GIFT_FORM_FIELD_CC = [
	{
		name: "lythuytinh",
		label: "Quà 1 ly thuỷ tinh",
		isGift: true,
		isRead: false,
	},
	{
		name: "binhgiunhiet",
		label: "Quà 1 bình giữ nhiệt",
		isGift: true,
		isRead: false,
	},
	{
		name: "candientu",
		label: "Quà 1 cân điện tử",
		isGift: true,
		isRead: false,
	},
];

const GiftExchange: React.FC<Props> = ({
	noGift,
	phone,
	isVerifyOtp,
	channel,
	dupForEnsure,
	onSubmit,
	goBack,
	initProduct,
}) => {
	const { register, methodsRef } = useFormContainer();
	const [opened, setOpened] = useState(false);
	const [confirmOpened, setConfirmOpened] = useState(false);
	const [allowExchange, setAllowExchange] = useState(0);
	const [allowExchangeEVL, setAllowExchangeEVL] = useState(0);
	const [exchangedGlass, setExchangedGlass] = useState(false);
	const totalPurchased = useRef(0);
	const totalELVLocPurchased = useRef(0);
	const [totalExchangeList, setTotalExchangeList] = useState<string[]>([]);
	const [totalExchangeGiftList, setTotalExchangeGiftList] = useState<string[]>(
		[]
	);

	const errorFunc = (message: string) => {
		toastSingleMode({
			type: "error",
			description: message,
		});
	};
	const getCountExchanged = async (phoneNumber: string) => {
		const data = await checkPhoneExchange(phoneNumber);
		setAllowExchange(
			data.data.data.totalProductPurchased > 6
				? 0
				: 6 - data.data.data.totalProductPurchased
		);
		setExchangedGlass(data.data.data.exchanged);
		setAllowExchangeEVL(
			data.data.data.totalELVLocPurchased > 4
				? 0
				: 4 - data.data.data.totalELVLocPurchased
		);
	};
	const [prizesExchange, prizesExchangeState] = useAsyncAction(
		apiPrizesExchange,
		{
			onSuccess: () => {
				const formValues = methodsRef.current.getValues();
				const totalGiftEnsure =
					Number(formValues.lythuytinh || 0) +
					Number(formValues.binhgiunhiet || 0) +
					Number(formValues.khanbong || 0) +
					Number(formValues.mayxaysinhto || 0) +
					Number(formValues.mayvatcam || 0) +
					Number(formValues.binhdunsieutoc || 0) +
					Number(formValues.vali || 0) +
					Number(formValues.binhll || 0) +
					Number(formValues.maymassage || 0) +
					Number(formValues.maydohuyetap || 0);

				if (!onSubmit) return;

				onSubmit(totalGiftEnsure > 0);
			},
			onFailed: (err: any) => {
				const { errors } = err.response.data;
				if (Array.isArray(errors)) {
					errors.forEach((val) => {
						if (val.code === "4223") {
							errorFunc(`Số lượng tồn kho quà không đủ`);
						}
						if (val.code === "4224") {
							errorFunc(
								`Số sản phẩm vượt quá quy định trên ngày. Vui lòng chọn lại`
							);
						}
						if (val.code === "4228") {
							errorFunc(`Đã hết lượt đổi quà trong ngày`);
						}
						if (val.code === "4230") {
							errorFunc(`Mỗi khách hàng chỉ đổi 01 Ly thuỷ tinh`);
						}
						if (val.code === "4234") {
							errorFunc(
								`Số lượng lốc ELV vượt quá quy định trên ngày. Vui lòng chọn lại`
							);
						}
					});
				} else {
					errorFunc(
						`Đã có lỗi ${err.response.status} xảy ra. Vui lòng thử lại sau`
					);
				}
			},
		}
	);

	/**
	 * SUBMIT
	 */

	const confirmSubmit = () => {
		const { getValues } = methodsRef.current;
		totalPurchased.current = 0;
		totalELVLocPurchased.current = 0;

		const formValues = getValues();

		const totalProductInput =
			Number(formValues.EGVani380 || 0) +
			Number(formValues.EGVani800 || 0) +
			Number(formValues.EGVaniIN380 || 0) +
			Number(formValues.EGVaniIN800 || 0) +
			Number(formValues.EGLM380 || 0) +
			Number(formValues.EGLM800 || 0) +
			Number(formValues.EGCF380 || 0) +
			Number(formValues.EGCF800 || 0) +
			Number(formValues.EGGH380 || 0) +
			Number(formValues.EGGH800 || 0) +
			Number(formValues.ELVLoc380 || 0);
		const totalGift =
			Number(formValues.lythuytinh || 0) +
			Number(formValues.binhgiunhiet || 0) +
			Number(formValues.candientu || 0) +
			Number(formValues.binhdunsieutoc || 0) +
			Number(formValues.mayxaysinhto || 0) +
			Number(formValues.vali || 0);

		const keys = Object.keys(formValues).filter((key) => formValues[key] > 0);
		setTotalExchangeList(
			keys
				.filter((item) => !!PRODUCT_FORM_FIELD_NAME_TO_LABEL[item])
				.map(
					(key) =>
						`${PRODUCT_FORM_FIELD_NAME_TO_LABEL[key]}: ${Number(
							formValues[key] || 0
						)}`
				)
		);

		setTotalExchangeGiftList(
			keys
				.filter((item) => !!GIFT_FORM_FIELD_NAME_TO_LABEL[item])
				.map(
					(key) =>
						`${GIFT_FORM_FIELD_NAME_TO_LABEL[key]}: ${Number(
							formValues[key] || 0
						)}`
				)
		);

		if (allowExchange === 0 && allowExchangeEVL === 0) {
			errorFunc("Đã hết lượt đổi quà trong ngày");
			return;
		}

		if (totalProductInput === 0) {
			errorFunc("Vui lòng nhập sản phẩm");
			return;
		}

		if (totalGift === 0) {
			errorFunc("Vui lòng nhập đầy đủ thông tin");
			return;
		}
		const total380 =
			Number(formValues.EGVani380 || 0) +
			Number(formValues.EGVaniIN380 || 0) +
			Number(formValues.EGLM380 || 0) +
			Number(formValues.EGCF380 || 0) +
			Number(formValues.EGGH380 || 0);
		const total800 =
			Number(formValues.EGVani800 || 0) +
			Number(formValues.EGVaniIN800 || 0) +
			Number(formValues.EGLM800 || 0) +
			Number(formValues.EGCF800 || 0) +
			Number(formValues.EGGH800 || 0);
		let totalEnsureGH380 = total380;
		let totalEnsure800 = 0;
		let totalLocEV =
			Number(formValues.ELVLoc380 || 0) > allowExchangeEVL
				? allowExchangeEVL
				: Number(formValues.ELVLoc380 || 0);
		let totalEnsure800Allow = 0;
		if (allowExchange === 0) {
			totalEnsure800Allow = 0;
		} else if (total800 >= allowExchange) {
			totalEnsure800 = allowExchange;
			totalEnsure800Allow = allowExchange;
		} else {
			totalEnsure800 = total800;
			totalEnsure800Allow = total800;
		}

		/* Scheme
			- Vali: 5 lon 800g
			- Mayxaysinhto: 3 lon 800
			- Binhdunsieutoc: 2 lon 800
			- Binhgiunhiet, Candientu: 2 LOC hoac 1 lon 800
			- Ly quai: 1 LOC hoạc 1 lon 380g hoac 1 lon 800
		*/
		/* Scheme kênh CC 
			- Binhgiunhiet, Candientu: 2 LOC hoac 1 lon 800
			- Ly quai: 1 LOC hoạc 1 lon 380g hoac 1 lon 800
		*/

		/* Vali */
		const gift1vali = Number(formValues.vali || 0);
		if (gift1vali > 0 && channel !== "CC") {
			if (totalEnsure800 >= 5 * gift1vali) {
				totalEnsure800 -= 5 * gift1vali;
				totalPurchased.current += 5 * gift1vali;
			} else {
				console.log(`sai ${gift1vali} vali`);

				errorFunc(
					`Sai scheme quà tặng. Còn lại ${allowExchange} lon được đổi trong ngày`
				);
				return;
			}
		}
		console.log("vali", { totalEnsure800, totalLocEV });

		/* Máy xay sinh tố */
		const gift1mayxaysinhto = Number(formValues.mayxaysinhto || 0);
		if (gift1mayxaysinhto > 0 && channel !== "CC") {
			if (totalEnsure800 >= 3 * gift1mayxaysinhto) {
				totalEnsure800 -= 3 * gift1mayxaysinhto;
				totalPurchased.current += 3 * gift1mayxaysinhto;
			} else {
				console.log(`sai ${gift1mayxaysinhto} máy xay sinh tố`);

				errorFunc(
					`Sai scheme quà tặng. Còn lại ${allowExchange} lon được đổi trong ngày`
				);
				return;
			}
		}
		console.log("may xay sinh to", { totalEnsure800, totalLocEV });

		/* Bình đun siêu tốc */
		const gift1binhdunsieutoc = Number(formValues.binhdunsieutoc || 0);
		if (gift1binhdunsieutoc > 0 && channel !== "CC") {
			if (totalEnsure800 >= 2 * gift1binhdunsieutoc) {
				totalEnsure800 -= 2 * gift1binhdunsieutoc;
				totalPurchased.current += 2 * gift1binhdunsieutoc;
			} else {
				console.log(`sai ${gift1binhdunsieutoc} Bình đun siêu tốc`);

				errorFunc(
					`Sai scheme quà tặng. Còn lại ${allowExchange} lon được đổi trong ngày`
				);
				return;
			}
		}
		console.log("binh dun sieu toc", { totalEnsure800, totalLocEV });
		/* Bình giữ nhiệt */
		const gift1binhgiunhiet = Number(formValues.binhgiunhiet || 0);
		if (gift1binhgiunhiet > 0) {
			if (totalLocEV >= gift1binhgiunhiet * 2) {
				totalLocEV -= gift1binhgiunhiet * 2;
				totalELVLocPurchased.current += gift1binhgiunhiet * 2;
			} else if (
				totalEnsure800 + Math.floor(totalLocEV / 2) >=
				gift1binhgiunhiet
			) {
				const totalEnsure800Purchased =
					gift1binhgiunhiet - Math.floor(totalLocEV / 2);
				totalPurchased.current += totalEnsure800Purchased;
				totalELVLocPurchased.current += Math.floor(totalLocEV / 2) * 2;
				totalLocEV -= Math.floor(totalLocEV / 2) * 2;
				totalEnsure800 -= totalEnsure800Purchased;
			} else {
				console.log(`sai ${gift1binhgiunhiet} binh giu nhiet`);
				errorFunc(
					`Sai scheme quà tặng. Còn lại ${allowExchange} lon được đổi trong ngày`
				);
				return;
			}
		}
		console.log("binh giu nhiet", { totalEnsure800, totalLocEV });
		/* Cân Điện Tử */
		const gift1candientu = Number(formValues.candientu || 0);
		if (gift1candientu > 0) {
			if (totalLocEV >= gift1candientu * 2) {
				totalLocEV -= gift1candientu * 2;
				totalELVLocPurchased.current += gift1candientu * 2;
			} else if (
				totalEnsure800 + Math.floor(totalLocEV / 2) >=
				gift1candientu
			) {
				const totalEnsure800Purchased =
					gift1candientu - Math.floor(totalLocEV / 2);
				totalPurchased.current += totalEnsure800Purchased;
				totalELVLocPurchased.current += Math.floor(totalLocEV / 2) * 2;
				totalLocEV -= Math.floor(totalLocEV / 2) * 2;
				totalEnsure800 -= gift1candientu + Math.floor(totalLocEV / 2);
			} else {
				console.log(`sai ${gift1candientu} cân điện tử`);
				errorFunc(
					`Sai scheme quà tặng. Còn lại ${allowExchange} lon được đổi trong ngày`
				);
				return;
			}
		}

		console.log("can dien tu", { totalEnsure800, totalLocEV });
		/* Ly quai */
		const gift1lythuytinh = Number(formValues.lythuytinh || 0);
		if (gift1lythuytinh > 0) {
			if (!dupForEnsure) {
				if (totalLocEV >= gift1lythuytinh) {
					totalLocEV -= gift1lythuytinh;
				} else if (totalEnsureGH380 + totalLocEV >= gift1lythuytinh) {
					totalEnsureGH380 -= gift1lythuytinh - totalLocEV;
				} else if (
					totalEnsureGH380 + totalEnsure800 + totalLocEV >=
					gift1lythuytinh
				) {
					totalEnsure800 -= gift1lythuytinh - totalEnsureGH380 - totalLocEV;
					totalEnsureGH380 = 0;
				} else {
					console.log(`sai ${gift1lythuytinh} ly thuy tinh}`);
					errorFunc(
						`Sai scheme quà tặng. Còn lại ${allowExchange} lon được đổi trong ngày`
					);
					return;
				}
			} else {
				errorFunc(
					`Sai scheme quà tặng. Ly thuỷ tinh chỉ dành cho khách hàng mới`
				);
				return;
			}
		}
		console.log("ly quai", { totalEnsure800, totalLocEV });

		console.log("total", {
			totalEnsure800,
			totalLocEV,
			totalPurchased: totalPurchased.current,
			totalELVLocPurchased: totalELVLocPurchased.current,
		});

		if (
			totalEnsure800 > 0 ||
			totalLocEV > 1 ||
			(totalEnsureGH380 + totalLocEV > 0 &&
				!formValues.lythuytinh &&
				!exchangedGlass &&
				!dupForEnsure)
		) {
			setOpened(true);
		} else {
			setConfirmOpened(true);
		}
	};

	const handleSubmit = () => {
		const { getValues } = methodsRef.current;

		const formValues = getValues();
		const totalProductInput =
			Number(formValues.EGVani380 || 0) +
			Number(formValues.EGVani800 || 0) +
			Number(formValues.EGVaniIN380 || 0) +
			Number(formValues.EGVaniIN800 || 0) +
			Number(formValues.EGLM380 || 0) +
			Number(formValues.EGLM800 || 0) +
			Number(formValues.EGCF380 || 0) +
			Number(formValues.EGCF800 || 0) +
			Number(formValues.EGGH380 || 0) +
			Number(formValues.EGGH800 || 0) +
			Number(formValues.ELVLoc380 || 0);
		if (totalProductInput === 0) {
			errorFunc("Vui lòng nhập sản phẩm");
			return;
		}
		prizesExchange({
			EGVani380:
				Number(formValues.EGVani380) > 0
					? Number(formValues.EGVani380)
					: undefined,
			EGVani800:
				Number(formValues.EGVani800) > 0
					? Number(formValues.EGVani800)
					: undefined,
			EGVaniIN380:
				Number(formValues.EGVaniIN380) > 0
					? Number(formValues.EGVaniIN380)
					: undefined,
			EGVaniIN800:
				Number(formValues.EGVaniIN800) > 0
					? Number(formValues.EGVaniIN800)
					: undefined,
			EGLM380:
				Number(formValues.EGLM380) > 0 ? Number(formValues.EGLM380) : undefined,
			EGLM800:
				Number(formValues.EGLM800) > 0 ? Number(formValues.EGLM800) : undefined,
			EGCF380:
				Number(formValues.EGCF380) > 0 ? Number(formValues.EGCF380) : undefined,
			EGCF800:
				Number(formValues.EGCF800) > 0 ? Number(formValues.EGCF800) : undefined,
			EGGH380:
				Number(formValues.EGGH380) > 0 ? Number(formValues.EGGH380) : undefined,
			EGGH800:
				Number(formValues.EGGH800) > 0 ? Number(formValues.EGGH800) : undefined,
			ELVLoc:
				Number(formValues.ELVLoc380) > 0
					? Number(formValues.ELVLoc380)
					: undefined,
			// gift
			lythuytinh:
				Number(formValues.lythuytinh) > 0
					? Number(formValues.lythuytinh)
					: undefined,
			binhgiunhiet:
				Number(formValues.binhgiunhiet) > 0
					? Number(formValues.binhgiunhiet)
					: undefined,
			candientu:
				Number(formValues.candientu) > 0
					? Number(formValues.candientu)
					: undefined,
			binhdunsieutoc:
				Number(formValues.binhdunsieutoc) > 0
					? Number(formValues.binhdunsieutoc)
					: undefined,
			mayxaysinhto:
				Number(formValues.mayxaysinhto) > 0
					? Number(formValues.mayxaysinhto)
					: undefined,
			vali: Number(formValues.vali) > 0 ? Number(formValues.vali) : undefined,
			totalPurchased:
				totalPurchased.current > 0 ? totalPurchased.current : undefined,
			totalELVLocPurchased:
				totalELVLocPurchased.current > 0
					? totalELVLocPurchased.current
					: undefined,
		});
	};

	const submitNoGift = () => {
		const { getValues } = methodsRef.current;

		const formValues = getValues();
		const totalProductInput =
			Number(formValues.EGVani380 || 0) +
			Number(formValues.EGVani800 || 0) +
			Number(formValues.EGVaniIN380 || 0) +
			Number(formValues.EGVaniIN800 || 0) +
			Number(formValues.EGLM380 || 0) +
			Number(formValues.EGLM800 || 0) +
			Number(formValues.EGCF380 || 0) +
			Number(formValues.EGCF800 || 0) +
			Number(formValues.EGGH380 || 0) +
			Number(formValues.EGGH800 || 0) +
			Number(formValues.ELVLoc380 || 0);
		if (totalProductInput === 0) {
			errorFunc("Vui lòng nhập sản phẩm");
			return;
		}
		prizesExchange({
			EGVani380:
				Number(formValues.EGVani380) > 0
					? Number(formValues.EGVani380)
					: undefined,
			EGVani800:
				Number(formValues.EGVani800) > 0
					? Number(formValues.EGVani800)
					: undefined,
			EGVaniIN380:
				Number(formValues.EGVaniIN380) > 0
					? Number(formValues.EGVaniIN380)
					: undefined,
			EGVaniIN800:
				Number(formValues.EGVaniIN800) > 0
					? Number(formValues.EGVaniIN800)
					: undefined,
			EGLM380:
				Number(formValues.EGLM380) > 0 ? Number(formValues.EGLM380) : undefined,
			EGLM800:
				Number(formValues.EGLM800) > 0 ? Number(formValues.EGLM800) : undefined,
			EGCF380:
				Number(formValues.EGCF380) > 0 ? Number(formValues.EGCF380) : undefined,
			EGCF800:
				Number(formValues.EGCF800) > 0 ? Number(formValues.EGCF800) : undefined,
			EGGH380:
				Number(formValues.EGGH380) > 0 ? Number(formValues.EGGH380) : undefined,
			EGGH800:
				Number(formValues.EGGH800) > 0 ? Number(formValues.EGGH800) : undefined,
			ELVLoc:
				Number(formValues.ELVLoc380) > 0
					? Number(formValues.ELVLoc380)
					: undefined,
		});
	};

	const handleSubmitLastest = () => {
		const { getValues } = methodsRef.current;

		const formValues = getValues();

		prizesExchange({
			EGVani380:
				Number(formValues.EGVani380) > 0
					? Number(formValues.EGVani380)
					: undefined,
			EGVani800:
				Number(formValues.EGVani800) > 0
					? Number(formValues.EGVani800)
					: undefined,
			EGVaniIN380:
				Number(formValues.EGVaniIN380) > 0
					? Number(formValues.EGVaniIN380)
					: undefined,
			EGVaniIN800:
				Number(formValues.EGVaniIN800) > 0
					? Number(formValues.EGVaniIN800)
					: undefined,
			EGLM380:
				Number(formValues.EGLM380) > 0 ? Number(formValues.EGLM380) : undefined,
			EGLM800:
				Number(formValues.EGLM800) > 0 ? Number(formValues.EGLM800) : undefined,
			EGCF380:
				Number(formValues.EGCF380) > 0 ? Number(formValues.EGCF380) : undefined,
			EGCF800:
				Number(formValues.EGCF800) > 0 ? Number(formValues.EGCF800) : undefined,
			EGGH380:
				Number(formValues.EGGH380) > 0 ? Number(formValues.EGGH380) : undefined,
			EGGH800:
				Number(formValues.EGGH800) > 0 ? Number(formValues.EGGH800) : undefined,
			ELVLoc:
				Number(formValues.ELVLoc380) > 0
					? Number(formValues.ELVLoc380)
					: undefined,
			// gift
			lythuytinh:
				Number(formValues.lythuytinh) > 0
					? Number(formValues.lythuytinh)
					: undefined,
			binhgiunhiet:
				Number(formValues.binhgiunhiet) > 0
					? Number(formValues.binhgiunhiet)
					: undefined,
			candientu:
				Number(formValues.candientu) > 0
					? Number(formValues.candientu)
					: undefined,
			binhdunsieutoc:
				Number(formValues.binhdunsieutoc) > 0
					? Number(formValues.binhdunsieutoc)
					: undefined,
			mayxaysinhto:
				Number(formValues.mayxaysinhto) > 0
					? Number(formValues.mayxaysinhto)
					: undefined,
			vali: Number(formValues.vali) > 0 ? Number(formValues.vali) : undefined,
			totalPurchased:
				totalPurchased.current > 0 ? totalPurchased.current : undefined,
			totalELVLocPurchased:
				totalELVLocPurchased.current > 0
					? totalELVLocPurchased.current
					: undefined,
		});
	};

	useEffect(() => {
		if (phone) getCountExchanged(phone);
	}, [phone]);

	useEffect(() => {
		if (initProduct) {
			methodsRef.current.reset({
				EGVani380: initProduct.EGVani380,
				EGVani800: initProduct.EGVani800,
				EGVaniIN380: initProduct.EGVaniIN380,
				EGVaniIN800: initProduct.EGVaniIN800,
				EGLM380: initProduct.EGLM380,
				EGLM800: initProduct.EGLM800,
				EGCF380: initProduct.EGCF380,
				EGCF800: initProduct.EGCF800,
				EGGH380: initProduct.EGGH380,
				EGGH800: initProduct.EGGH800,
			});
		}
	}, [initProduct, methodsRef]);

	return (
		<General helmet={<title>Thông tin đổi quà</title>}>
			<Container>
				<div className="t-giftExchange">
					<section className="mt-2 pt-4">
						<div className="u-pb-5">
							<Heading>Sản phẩm</Heading>
						</div>
						<FormContainer validationSchema={{}} register={register}>
							<div className="d-flex t-giftExchange_title">
								<div className="t-giftExchange_title-label" />
								<div className="d-flex flex-grow-1 justify-content-between">
									<div className="w-40">
										<Text>Lon 380g</Text>
									</div>
									<div className="w-40">
										<Text>Lon 800g</Text>
									</div>
								</div>
							</div>
							{PRODUCT_FORM_FIELD.map((val, idx) => (
								<FormField
									key={`item-${idx.toString()}`}
									label={<span>{val.label}</span>}
									modifiers="giftInfoMT"
								>
									<div className="d-flex justify-content-between">
										<div className="w-40">
											<TextfieldHookForm
												name={`${val.name}380`}
												type="number"
												min={0}
												placeholder="0"
											/>
										</div>
										{val.isMultiple && (
											<div className="w-40">
												<TextfieldHookForm
													name={`${val.name}800`}
													type="number"
													min={0}
													placeholder="0"
												/>
											</div>
										)}
									</div>
								</FormField>
							))}
							{!noGift && (
								<>
									<div className="u-pb-5 pt-4">
										<Heading>Quà tặng Ensure</Heading>
									</div>
									{(channel === "CC"
										? ENSURE_GIFT_FORM_FIELD_CC
										: ENSURE_GIFT_FORM_FIELD
									).map((val, idx) => (
										<FormField
											key={`gift-${idx.toString()}`}
											label={val.label}
											modifiers="giftInfoMT"
										>
											<div
												className="w-60"
												style={{
													opacity:
														val.name === "lythuytinh" &&
														(exchangedGlass || dupForEnsure)
															? 0.5
															: 1,
												}}
											>
												<TextfieldHookForm
													name={val.name}
													type="number"
													min={0}
													placeholder="0"
													readOnly={
														val.isRead ||
														(val.name === "lythuytinh" &&
															(exchangedGlass || dupForEnsure))
													}
												/>
											</div>
										</FormField>
									))}
								</>
							)}
							<div className="d-flex align-items-center">
								<div className="ml-auto mr-auto u-ml-sm-10 u-mt-15">
									<Button
										modifiers="mediumSize"
										onClick={goBack}
										loading={prizesExchangeState.loading}
										disabled={prizesExchangeState.loading}
									>
										Quay lại
									</Button>
								</div>
								<div className="ml-auto mr-auto u-ml-sm-10 u-mt-15">
									<Button
										modifiers="mediumSize"
										onClick={() => {
											if (noGift) {
												submitNoGift();
											} else {
												confirmSubmit();
											}
										}}
										disabled={prizesExchangeState.loading}
										loading={prizesExchangeState.loading}
									>
										TIẾP TỤC
									</Button>
								</div>
							</div>
						</FormContainer>
					</section>
				</div>
			</Container>
			<Modal
				heading="Thông báo"
				isOpen={opened}
				closable
				shouldCloseOnEsc
				modifiers="confirm"
				shouldCloseOnOverlayClick
				onCloseModal={() => setOpened(false)}
			>
				<ul style={{ marginBottom: 24, paddingLeft: 16 }}>
					{totalExchangeList.map((val, idx) => (
						<li key={`item-${idx.toString()}`}>
							<Text modifiers={["yankeesBlue", "16x24"]}>{val}</Text>
						</li>
					))}
				</ul>
				<ul style={{ marginBottom: 24, paddingLeft: 16 }}>
					{totalExchangeGiftList.map((val, idx) => (
						<li key={`item-${idx.toString()}`}>
							<Text modifiers={["yankeesBlue", "16x24"]}>{val}</Text>
						</li>
					))}
				</ul>
				<Text centered modifiers={["yankeesBlue", "18x26"]}>
					Bạn vẫn có thể đổi thêm quà. <br />
					Bạn có chắc chắn muốn đi tiếp không?
				</Text>
				<div className="d-flex align-items-center">
					<div className="ml-auto mr-auto u-ml-sm-10 u-mt-15">
						<Button
							onClick={() => setOpened(false)}
							loading={prizesExchangeState.loading}
						>
							Thêm quà
						</Button>
					</div>
					<div className="ml-auto mr-auto u-ml-sm-10 u-mt-15">
						<Button
							modifiers="mediumSize"
							onClick={handleSubmitLastest}
							loading={prizesExchangeState.loading}
							disabled={prizesExchangeState.loading}
						>
							TIẾP TỤC
						</Button>
					</div>
				</div>
			</Modal>
			<Modal
				heading="Thông báo"
				isOpen={confirmOpened}
				closable
				shouldCloseOnEsc
				modifiers="confirm"
				shouldCloseOnOverlayClick
				onCloseModal={() => setConfirmOpened(false)}
			>
				<ul style={{ marginBottom: 24, paddingLeft: 16 }}>
					{totalExchangeList.map((val, idx) => (
						<li key={`item-${idx.toString()}`}>
							<Text modifiers={["yankeesBlue", "16x24"]}>{val}</Text>
						</li>
					))}
				</ul>
				<ul style={{ marginBottom: 24, paddingLeft: 16 }}>
					{totalExchangeGiftList.map((val, idx) => (
						<li key={`item-${idx.toString()}`}>
							<Text modifiers={["yankeesBlue", "16x24"]}>{val}</Text>
						</li>
					))}
				</ul>
				<Text centered modifiers={["yankeesBlue", "18x26"]}>
					Vui lòng xác nhận số lượng quà tặng
				</Text>
				<div className="d-flex align-items-center">
					<div className="ml-auto mr-auto u-ml-sm-10 u-mt-15">
						<Button
							onClick={() => setConfirmOpened(false)}
							loading={prizesExchangeState.loading}
						>
							Quay lại
						</Button>
					</div>
					<div className="ml-auto mr-auto u-ml-sm-10 u-mt-15">
						<Button
							modifiers="mediumSize"
							onClick={handleSubmit}
							loading={prizesExchangeState.loading}
							disabled={prizesExchangeState.loading}
						>
							Xác nhận
						</Button>
					</div>
				</div>
			</Modal>
		</General>
	);
};

export default GiftExchange;
