import { useHistory } from "react-router-dom";

import { Loading } from "components/atoms/Loading";
import { toastSingleMode } from "components/atoms/Toastify";
import { General } from "components/pages/general";
import PATHS from "constants/paths";
import { BasePageProps } from "helpers/component";
import { useAsyncAction } from "hooks/useAsyncAction";
import useDidMount from "hooks/useDidMount";
import { useUserAuthAction } from "hooks/useUserAuthAction";

const IndexPage: React.FC<BasePageProps> = () => {
	const history = useHistory();

	const { handleLogout } = useUserAuthAction();

	const [handleLogoutExec, handleLogoutState] = useAsyncAction(handleLogout, {
		onSuccess: () => {
			toastSingleMode({ message: "Đăng xuất thành công!", type: "success" });
			history.push(PATHS.LOGIN);
		},
		onFailed: () => {
			toastSingleMode({
				message: "Đã xảy ra lỗi!",
				type: "error",
			});
		},
	});

	useDidMount(() => {
		handleLogoutExec();
	});

	return (
		<General helmet={<title>Đăng xuất</title>}>
			{handleLogoutState.loading && <Loading />}
		</General>
	);
};

export default IndexPage;
