import React from "react";

import { mapModifiers } from "helpers/component";

export type TextModifiers =
	| "black"
	| "spanishBlue"
	| "charlestonGreen"
	| "yankeesBlue"
	| "18x26"
	| "22x28"
	| "12x18"
	| "16x24"
	| "white"
	// transform
	| "uppercase"
	// style
	| "italic"
	| "stoke"
	// Align
	| "center";
export interface Props {
	centered?: boolean;
	children?: React.ReactNode;
	modifiers?: TextModifiers[];
}

export const Text: React.FC<Props> = ({ centered, children, modifiers }) => (
	<p className={mapModifiers("a-text", centered && "centered", modifiers)}>
		{children}
	</p>
);
