import React, { useCallback, useEffect, useState } from "react";

import { animated, useSpring } from "react-spring";

import Circle from "assets/images/element/element-07.png";
import Wheel from "assets/images/element/element-09.png";
import { Button } from "components/atoms/Button";
import { Text } from "components/atoms/Text";
import { toastSingleMode } from "components/atoms/Toastify";
import { Modal } from "components/organisms/Modal";
import { delay } from "helpers/component";
import { LOCAL_STORAGE } from "helpers/constants";

interface LuckyWheelProps {
	total?: number;
	loading?: boolean;
	onClick?: () => Promise<string | null>;
	onEnd?: () => void;
	onTimeout?: () => void;
}

const AWARD_MAPPING: { [key: string]: number } = {
	LY_THUY_TINH: 6,
	SACHET: 5,
	BINH_LL: 4,
	NHIET_KE_ML: 3,
	TUI_Y_TE: 2,
	BO_LY_MUONG: 1,
};

const mappingPrize = (gift: string): string => {
	switch (gift) {
		case "LY_THUY_TINH":
			return "Ly thuỷ tinh";
		case "SACHET":
			return "Sachet Ensure";
		case "BINH_LL":
			return "Bình nhựa Lock&Lock";
		case "NHIET_KE_ML":
			return "Nhiệt kế Microlife";
		case "TUI_Y_TE":
			return "Túi Y tế";
		case "BO_LY_MUONG":
			return "Bộ ly muỗng";
		default:
			return "";
	}
};

const DURATION = 5000;

const LuckyWheel: React.FC<LuckyWheelProps> = ({
	total = 0,
	loading,
	onClick,
	onEnd,
	onTimeout,
}) => {
	const [count, setCount] = useState(
		Number(window.localStorage.getItem(LOCAL_STORAGE.COSY_COUNTDOWN_LUCKY_DRAW))
	); // 10 minutes in milliseconds

	const [drawing, setDrawing] = useState<boolean>(false);
	const [modal, setModal] = useState<{
		isOpen: boolean;
		content: React.ReactNode;
	}>({
		isOpen: false,
		content: null,
	});

	const [props, api] = useSpring(() => ({
		rotate: 360,
		padding: 20,
	}));
	const draw = useCallback(async () => {
		try {
			setDrawing(true);
			const value = await onClick?.();
			if (!value) {
				return null;
			}

			const prizeId: number = AWARD_MAPPING[value!];
			const destOffset = prizeId * 60;

			api.start({
				from: {
					rotate: 0,
				},
				to: {
					rotate: 360 * 10 + destOffset,
				},
				config: {
					duration: DURATION,
					easing: (t) => 2 * t - t ** 2,
				},
			});

			await delay(DURATION).then(() => {
				setModal({
					isOpen: true,
					content: value ? mappingPrize(value) : "",
				});
			});

			return null;
		} catch (error) {
			return error;
		} finally {
			setDrawing(false);
		}
	}, [onClick, api]);

	const onCloseModal = useCallback(() => {
		setModal({ ...modal, isOpen: false });
		onEnd?.();
	}, [modal, onEnd]);

	useEffect(() => {
		const interval = setInterval(() => {
			setCount((prevCount) => {
				if (prevCount === 0) {
					return prevCount;
				}

				window.localStorage.setItem(
					LOCAL_STORAGE.COSY_COUNTDOWN_LUCKY_DRAW,
					(prevCount - 1).toString()
				);
				return prevCount - 1;
			});
		}, 1000);

		if (count === 0) {
			clearInterval(interval);
			if (onTimeout) {
				onTimeout();
			}
		}

		return () => {
			clearInterval(interval);
		};
	}, [count, onTimeout]);

	return (
		<div className="t-luckyWheel">
			<div className="t-luckyWheel_circle">
				<div className="wheel">
					<animated.img src={Wheel} alt="wheel" style={props} />
				</div>
				<div className="circle">
					<img src={Circle} alt="circle" />
				</div>
			</div>
			<div
				style={{
					textAlign: "center",
					marginTop: "12px",
					position: "relative",
					zIndex: 3,
				}}
			>
				<div className="t-luckyWheel_button">
					<Button
						onClick={draw}
						disabled={drawing || total === 0 || loading}
						loading={loading}
					>
						Quay thưởng
					</Button>
				</div>
			</div>
			<Modal isOpen={modal.isOpen} closable={false} modifiers="wheels">
				<div className="t-luckyWheel_modal">
					<Text modifiers={["yankeesBlue", "18x26", "center"]}>
						Chúc mừng bạn đã trúng {modal.content}
					</Text>
					<div className="t-luckyWheel_button">
						<Button onClick={onCloseModal}>Tiếp tục</Button>
					</div>
				</div>
			</Modal>
		</div>
	);
};

LuckyWheel.defaultProps = {};

export default LuckyWheel;
