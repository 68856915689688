import React, { ReactNode, useEffect } from "react";

import { useHistory } from "react-router-dom";

interface Props {
	children: ReactNode;
}

export const AppNavigationProvider: React.FC<Props> = (props) => {
	const { children } = props;
	const history = useHistory();

	useEffect(() => {
		const navigationEventController = (e: CustomEvent) => {
			const { detail = {} } = e;
			switch (detail.type) {
				case "push": {
					history.push(detail.path, detail.state);
					break;
				}
				case "replace": {
					history.replace(detail.path, detail.state);
					break;
				}
				case "goBack": {
					history.go(-1);
					break;
				}
				default:
					break;
			}
		};

		window.addEventListener("route", navigationEventController);

		return () => {
			window.removeEventListener("route", navigationEventController);
		};
	}, [history]);

	return <>{children}</>;
};
