/* eslint-disable camelcase */
import http from "services/http";

import {
	CustomerByPhone,
	CustomerPreSaveParamsTypes,
	FindCustomerByPhoneParamsTypes,
	OtpVerification,
	PreActiveCustomerTypes,
	SavingCustomer,
	SendOtpParamsTypes,
} from "./types";

export const apiFindCustomerByPhone = (
	params: FindCustomerByPhoneParamsTypes
) =>
	http.get<BaseAPIResponse<CustomerByPhone>>(
		`/customers/${params.phone}?is_glucerna=${params.is_glucerna}&is_exchange_gift=${params.is_exchange_gift}`,
		{
			requireAuthentication: true,
		}
	);

export const apiSendOtp = (payload: SendOtpParamsTypes) =>
	http.post(
		`/customers/otp/send?is_glucerna=${payload.is_glucerna}`,
		payload.data,
		{ requireAuthentication: true }
	);

export const apiVerifyOtp = (payload: OtpVerification) =>
	http.post(
		`/customers/otp/verify?is_glucerna=${payload.is_glucerna}`,
		payload.params,
		{ requireAuthentication: true }
	);

export const postCustomerPreSave = async (params: CustomerPreSaveParamsTypes) =>
	http.post("/customers/pre-save", params, { requireAuthentication: true });

export const getPreActiveCustomer = async (
	params: string
): Promise<PreActiveCustomerTypes> => {
	const res = await http.get(`/customers/pre-active/${params}`, {
		requireAuthentication: true,
	});
	return res.data.data;
};

export const apiSaveCustomer = (
	{
		address,
		birthday,
		firstname,
		lastname,
		gender,
		email,
		phone,
		sample,
		sachet,
		sachet_glucerna,
		exchange,
		no_exchange,
		ensure_target,
		glucerna_target,
		exchange_immune,
		dup_for_ensure,
		exchange_sachet,
		policy_and_condition,
		no_share_info,
		gift,
		save_nemo,
		is_internal,
	}: SavingCustomer,
	isGlucerna: number
) =>
	http.post<BaseAPIResponse<CustomerByPhone>>(
		`/customers/save?is_glucerna=${isGlucerna}`,
		{
			address,
			firstname,
			lastname,
			gender,
			email,
			phone,
			sachet,
			sachet_glucerna,
			sample,
			exchange,
			no_exchange,
			birthday,
			ensure_target,
			glucerna_target,
			exchange_immune,
			dup_for_ensure,
			exchange_sachet,
			policy_and_condition,
			no_share_info,
			gift,
			save_nemo,
			is_internal,
		},
		{ requireAuthentication: true }
	);

export const apiNemoVerify = async (phone: string) => {
	const res = await http.get<BaseAPIResponse<{ dupForEnsure: boolean }>>(
		`/customers/${phone}/nemo-verify`,
		{
			requireAuthentication: true,
		}
	);
	return res.data.data;
};

export const apiSubmitSignature = async (
	signature: File,
	save_nemo: boolean
) => {
	const formData = new FormData();

	formData.append("signature", signature);
	formData.append("save_nemo", save_nemo.toString());

	const res = await http.post<BaseAPIResponse<{ path: string }>>(
		"/customers/signatures",
		formData,
		{
			headers: {
				"content-type": "multipart/form-data",
			},
			requireAuthentication: true,
		}
	);

	return res.data.data;
};
