import React from "react";

import { Text } from "components/atoms/Text";
import { mapModifiers } from "helpers/component";

type Modifier =
	| "customerInfo"
	| "giftInfo"
	| "giftInfoMT"
	| "draw"
	| "block"
	| "noMargin";

interface Props {
	label?: React.ReactNode;
	children: React.ReactNode;
	modifiers?: Modifier | Modifier[];
}

export const FormField: React.FC<Props> = ({ children, label, modifiers }) => (
	<div className={mapModifiers("m-formfield", modifiers)}>
		{label && <Text>{label}</Text>}
		<div className="m-formfield_input">{children}</div>
	</div>
);
