export default {
	APP: "/",
	LOGIN: "/dang-nhap",
	LOGOUT: "/logout",
	CUSTOMERINFO: "/thong-tin-khach-hang",
	GIFTINFO: "/thong-tin-doi-qua",
	DRAW: "/quay-vong-quay",
	PHOTO: "/chup-hinh",
	404: "/404",
} as const;
