import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type ProductTypes = "ensure" | "glucerna" | "default";

export type TempDataTypes = {
	firstname: string;
	lastname: string;
	gender: string;
	email?: string;
	phone: string;
	address: string;
	birthday: string;
};
export interface StoresReducerState {
	channel?: "MT" | "GT" | "CV" | "CC";
	code?: string;
	name?: string;
	id?: number;
	type?: ProductTypes;
	tempData?: TempDataTypes;
}

const initialState: StoresReducerState = {};

const storesSlice = createSlice({
	name: "stores",
	initialState,
	reducers: {
		updateStoreInfors: (
			state,
			action: PayloadAction<{
				channel: "MT" | "GT";
				code: string;
				name: string;
				id: number;
			}>
		) => {
			state.channel = action.payload.channel;
			state.code = action.payload.code;
			state.name = action.payload.name;
			state.id = action.payload.id;
		},
		updateProductType: (state, action: PayloadAction<ProductTypes>) => {
			state.type = action.payload;
		},
		saveTempFormData: (state, action: PayloadAction<TempDataTypes>) => {
			state.tempData = action.payload;
		},
		clearStoreInfors: (state) => {
			state.channel = undefined;
			state.code = undefined;
			state.name = undefined;
			state.id = undefined;
		},
	},
	extraReducers: () => {},
});

export default storesSlice;
