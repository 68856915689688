import React from "react";

import { Helmet } from "react-helmet";

export interface Props {
	helmet?: React.ReactNode;
	children?: React.ReactNode;
}

export const General: React.FC<Props> = ({ helmet, children }) => {
	return (
		<div className="p-general">
			<Helmet>{helmet}</Helmet>
			{children}
		</div>
	);
};
