import { createAsyncThunk } from "@reduxjs/toolkit";

import { apiGetUserProfile } from "services/PG";
import { getCustomerSessionInfo } from "services/Sessions";

export const initialUserSessionAction = createAsyncThunk(
	"auth/initialUserSession",
	async () => {
		const userSession = getCustomerSessionInfo();
		if (!userSession) throw new Error("Customer session not found");

		const res = await apiGetUserProfile();
		return {
			userProfile: res?.data?.data,
		};
	}
);
