import React from "react";

import { ToastContainer, toast as toastInstance, Slide } from "react-toastify";

import { Icon } from "components/atoms/Icon";
import { mapModifiers } from "helpers/component";

const NotificationIcons = {
	error: "red-exclamation-circle",
	warning: "yellow-exclamation-triangle",
	info: "blue-info-circle",
	success: "green-check-circle",
} as const;

type Type = "error" | "warning" | "info" | "success";

export interface Props {
	autoClose?: number | false;
}

export interface ToastifyMessageProps {
	type: Type;
	message?: string;
	description?: React.ReactNode;
	closeToast?: () => void;
}

let currentToastId: React.ReactText | null;

export const toastSingleMode = (props: ToastifyMessageProps) => {
	if (currentToastId) {
		toastInstance.dismiss(currentToastId);
	}

	currentToastId = toastInstance(
		({ closeToast }) =>
			closeToast && (
				<ToastifyMessage
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...props}
					closeToast={closeToast}
				/>
			)
	);

	return currentToastId;
};

export const toast = toastInstance;

export const ToastifyMessage: React.FC<ToastifyMessageProps> = ({
	type,
	message,
	description,
	closeToast,
}) => (
	<div
		className={mapModifiers(
			"a-toastify_message",
			description ? "has-description" : undefined
		)}
	>
		<div className="a-toastify_sign">
			<Icon iconName={NotificationIcons[type]} />
		</div>
		<div className="a-toastify_content">
			<div className="a-toastify_header">{message}</div>
			{description && (
				<div className="a-toastify_description">{description}</div>
			)}
		</div>
		<div className="a-toastify_close">
			<Icon iconName="white-close-circle" clickable onClick={closeToast} />
		</div>
	</div>
);

export const Toastify: React.FC<Props> = ({ children, autoClose = 5000 }) => (
	<div className="a-toastify">
		{children}
		<ToastContainer
			hideProgressBar
			transition={Slide}
			position="top-right"
			autoClose={autoClose}
			pauseOnHover
			closeButton={false}
			closeOnClick={false}
		/>
	</div>
);
