import React, { forwardRef, useCallback } from "react";

import { mapModifiers } from "helpers/component";
import { ConnectForm, UseFormProps } from "helpers/form";

export interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
	errorMessage?: string;
}

export const PhoneField = forwardRef<HTMLInputElement, Props>(
	({ disabled, errorMessage, onChange, ...innerProps }, ref) => {
		const onChangeFactory = useCallback(
			(event: React.ChangeEvent<HTMLInputElement>) => {
				if (event.target.value.match(/\D+/)) {
					event.target.value = event.target.value.replace(/\D+/g, "");
				}
				if (onChange) {
					onChange(event);
				}
			},
			[onChange]
		);

		return (
			<>
				<div className={mapModifiers("a-phonefield", errorMessage && "error")}>
					<input
						{...innerProps}
						onChange={onChangeFactory}
						className="a-phonefield_input"
						type="tel"
						ref={ref}
					/>
					{errorMessage && (
						<div className="a-phonefield_errormessage">{errorMessage}</div>
					)}
				</div>
			</>
		);
	}
);

interface PhonefieldHookFormProps extends Props {
	name: string;
}

export const PhonefieldHookForm = (props: PhonefieldHookFormProps) => (
	<ConnectForm>
		{({ register, errors }: UseFormProps) => (
			<PhoneField
				ref={register}
				errorMessage={
					errors[props.name] ? errors[props.name].message : undefined
				}
				{...props}
			/>
		)}
	</ConnectForm>
);
