import React from "react";

import {
	Container as ContainerBootstrap,
	Row as RowBootstrap,
	Col as ColBootstrap,
	ContainerProps,
	RowProps,
	ColProps,
} from "react-bootstrap";

export const Container: React.FC<ContainerProps> = ({
	fluid,
	as,
	bsPrefix,
	children,
	className,
}) => (
	<ContainerBootstrap
		fluid={fluid}
		as={as}
		bsPrefix={bsPrefix}
		className={`o-grid_container ${className || ""}`}
	>
		{children}
	</ContainerBootstrap>
);

export const Row: React.FC<RowProps> = ({
	as,
	bsPrefix,
	children,
	className,
	noGutters,
	xl,
	lg,
	md,
	sm,
	xs,
}) => (
	<RowBootstrap
		as={as}
		bsPrefix={bsPrefix}
		className={className}
		noGutters={noGutters}
		xl={xl}
		lg={lg}
		md={md}
		sm={sm}
		xs={xs}
	>
		{children}
	</RowBootstrap>
);

export const Col: React.FC<ColProps> = ({
	as,
	bsPrefix,
	children,
	className,
	xl,
	lg,
	md,
	sm,
	xs,
}) => (
	<ColBootstrap
		as={as}
		bsPrefix={bsPrefix}
		className={className}
		xl={xl}
		lg={lg}
		md={md}
		sm={sm}
		xs={xs}
	>
		{children}
	</ColBootstrap>
);
