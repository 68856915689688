import React from "react";

import useDerivedStateFromProps from "hooks/useDerivedStateFromProps";
import { useAppDispatch } from "store";
import { initialUserSessionAction } from "store/Auth";

/**
 * Do things that is required for the app iniatilly.
 * e.g. take token, fetch user info, etc.
 */
export const AppInitializer: React.FC = () => {
	const dispatch = useAppDispatch();

	useDerivedStateFromProps(() => {
		dispatch(initialUserSessionAction());
	}, undefined);

	return null;
};
