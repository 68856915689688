import { useEffect, useState } from "react";

interface InnertWindow {
	width: number;
	height: number;
}

const getWindowDimensions = (): InnertWindow => {
	const { innerWidth, innerHeight } = window;
	return {
		width: innerWidth,
		height: innerHeight,
	};
};

const useWindowDimensions = (): InnertWindow => {
	const [windowDimensions, setWindowDimensions] = useState(
		getWindowDimensions()
	);

	useEffect(() => {
		const handleResize = (): void => {
			setWindowDimensions(getWindowDimensions());
		};
		window.addEventListener("resize", handleResize);
		return (): void => window.removeEventListener("resize", handleResize);
	}, []);

	return windowDimensions;
};

export default useWindowDimensions;
