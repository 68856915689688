/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useRef, useState } from "react";

import { toastSingleMode } from "components/atoms/Toastify";
import { General } from "components/pages/general";
import GiftExchange from "components/templates/GiftExchange";
import LuckyWheel from "components/templates/LuckyWheel";
import ScanQr from "components/templates/ScanQr";
import SelectBill from "components/templates/SelectBill";
import { SavedCustomer } from "pages";
import CustomerInfo, { BackupData } from "pages/CustomerInfo";
import Photo from "pages/Photo";
import { apiCustomerPrizesDraw } from "services/Draw";
import { ProductOfBillSelect } from "services/PG/types";
import { config, getLocalStorage, setLocalStorage } from "services/Sessions";
import { useAppSelector } from "store";

const Home: React.FC = () => {
	const [step, setStep] = useState(0);
	const [acceptDraw, setAcceptDraw] = useState(false);
	const { channel } = useAppSelector((store) => store.stores);
	const { userProfile } = useAppSelector((store) => store.auth);
	const savedCustomerRef = useRef<SavedCustomer | undefined>(undefined);
	const backupCustomerRef = useRef<BackupData | undefined>(undefined);
	const remainingGifRef = useRef<number | undefined>(undefined);
	const [initProduct, setInitProduct] = useState<
		ProductOfBillSelect | undefined
	>(undefined);
	const infoStep = getLocalStorage("infoStep");
	const [isVerifyOtp, setIsVerifyOtp] = useState(true);

	// useEffect(() => {
	// 	if (infoStep) setStep(Number(infoStep));
	// }, [infoStep]);

	/**
	 * Back to first page
	 */
	const backToStart = () => {
		savedCustomerRef.current = undefined;
		setStep(2);
		window.localStorage.removeItem("infoStep");
	};

	const handleReload = () => {
		window.location.reload();
	};

	const handleGotoNextStep = () => {
		setStep((prevStep) => prevStep + 1);
	};

	const getRemainingGift = (quantity: number) => {
		remainingGifRef.current = quantity;
	};

	const getSavedCustomer = (savedCustomer: SavedCustomer) => {
		savedCustomerRef.current = savedCustomer;
		setIsVerifyOtp(Boolean(savedCustomer.isVerifyOtp));
	};

	const handleSubmitGiftInfo = (hasDraw: boolean) => {
		setAcceptDraw(hasDraw);
		handleGotoNextStep();
	};

	const handleGotoPrevStep = () => {
		setStep(2);
	};

	const handleReset = () => {
		window.location.reload();
	};

	const gotoLuckyDraw = useCallback(
		(hasDraw?: boolean) => {
			if (channel === "GT" && !hasDraw && acceptDraw) {
				setStep(6);
			} else {
				handleReset();
			}
		},
		[channel, acceptDraw]
	);

	useEffect(() => {
		if (channel === "MT" && !getLocalStorage(config.saveQrCode)) {
			setStep(1);
		} else {
			setStep(2);
		}
	}, [channel]);

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	}, [step]);
	const errorFunc = (message: string) => {
		toastSingleMode({
			type: "error",
			description: message,
		});
	};
	const drawAction = async () => {
		try {
			const response = await apiCustomerPrizesDraw();
			return response.data.data.prizeCode;
		} catch (error: any) {
			const { errors } = error.response.data;
			if (Array.isArray(errors)) {
				errors.forEach((val) => {
					if (val.code === "4227") {
						errorFunc(`Kiểm tra lại tồn kho`);
					}
				});
			} else {
				errorFunc(`Đã xãy ra lỗi xảy ra!`);
			}
			return null;
		}
	};

	// eslint-disable-next-line consistent-return
	const renderContent = () => {
		switch (step) {
			case 0:
				return null;
			case 1:
				return (
					<ScanQr
						handleSuccess={() => {
							setLocalStorage(config.saveQrCode, "1");
							setStep(2);
						}}
					/>
				);
			case 2:
				return (
					<CustomerInfo
						onSubmit={() => (channel === "MT" ? setStep(3) : setStep(4))}
						onReload={handleReload}
						getRemainingGift={getRemainingGift}
						savedCustomer={savedCustomerRef.current}
						backupData={backupCustomerRef.current}
						saveBackupData={(data) => {
							backupCustomerRef.current = data;
						}}
						saveSignature={(data) => {
							if (backupCustomerRef.current) {
								backupCustomerRef.current = {
									...backupCustomerRef.current,
									signature: data,
								};
							}
						}}
						getSavedCustomer={getSavedCustomer}
						handleBack={backToStart}
						channel={
							(userProfile?.channel as "GT" | "MT" | "CV" | "CC") || channel
						}
					/>
				);
			case 3:
				return (
					<SelectBill
						handleContinue={(data) => {
							setStep(4);
							setInitProduct(data);
						}}
					/>
				);
			case 4:
				return (
					<GiftExchange
						noGift={backupCustomerRef.current?.noExchangeGift}
						phone={savedCustomerRef.current?.phone}
						dupForEnsure={backupCustomerRef.current?.dupForEnsure}
						isVerifyOtp={isVerifyOtp}
						channel={channel || "GT"}
						onSubmit={handleSubmitGiftInfo}
						goBack={handleGotoPrevStep}
						initProduct={initProduct}
					/>
				);
			case 5:
				return <Photo onSubmit={handleReset} />;
			case 6:
				return (
					<LuckyWheel total={93} onClick={drawAction} onEnd={handleReset} />
				);
			default:
		}
	};
	return (
		<General helmet={<title>Activation</title>}>{renderContent()}</General>
	);
};

export default Home;
