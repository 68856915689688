import React from "react";

export type Props = React.InputHTMLAttributes<HTMLInputElement>;

export const Checkbox: React.FC<Props> = ({ children, id, ...innerProps }) => (
	<div className="a-checkbox">
		<label className="a-checkbox_label" htmlFor={id as string}>
			<input
				{...innerProps}
				className="a-checkbox_input"
				id={id}
				type="checkbox"
			/>
			<div className="a-checkbox_body">
				<div className="a-checkbox_inner" />
			</div>
			{!!children && <div className="a-checkbox_content">{children}</div>}
		</label>
	</div>
);
