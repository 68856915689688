import React, { useCallback, useReducer } from "react";

import produce from "immer";

import { Button } from "components/atoms/Button";
import { Heading } from "components/atoms/Heading";
import { Text } from "components/atoms/Text";
import { Col, Row } from "components/organisms/Grid";
import { Modal } from "components/organisms/Modal";

interface PromiseRef {
	resolve: () => void;
	reject: () => void;
}

export interface DialogServiceOption {
	title?: string;
	content?: React.ReactNode;
	extraContent?: React.ReactNode;
	okLabel: string;
	cancelLabel?: string;
	type?: "info" | "success" | "error" | "warning";
}

export interface DialogServiceState extends DialogServiceOption {
	open: boolean;
}

export const DialogContext = React.createContext<
	(options: DialogServiceOption) => Promise<void>
>(Promise.reject);

type ActionType =
	| {
			type: "OPEN";
			payload: DialogServiceOption;
	  }
	| {
			type: "CLOSE";
	  };

const initialState: DialogServiceState = {
	open: false,
	okLabel: "",
};

const reducer = produce((draft: DialogServiceOption, action: ActionType) => {
	switch (action.type) {
		case "OPEN": {
			return {
				...draft,
				...action.payload,
				open: true,
			};
		}
		case "CLOSE": {
			return initialState;
		}
		default: {
			return { ...draft, open: false };
		}
	}
});

const DialogServiceProvider: React.FC = ({ children }) => {
	const [dialogServiceState, dispatch] = useReducer(reducer, initialState);
	const promiseRef = React.useRef<PromiseRef>();

	const openDialog = (options: DialogServiceOption) => {
		dispatch({ type: "OPEN", payload: options });
		return new Promise<void>((resolve, reject) => {
			promiseRef.current = { resolve, reject };
		});
	};

	const handleCloseDialog = useCallback(() => {
		dispatch({ type: "CLOSE" });
	}, []);

	const handleOk = useCallback(() => {
		if (promiseRef.current && dialogServiceState.okLabel) {
			promiseRef.current.resolve();
		}
		handleCloseDialog();
	}, [dialogServiceState.okLabel, handleCloseDialog]);

	const handleCancel = useCallback(() => {
		if (promiseRef.current && dialogServiceState.cancelLabel) {
			promiseRef.current.reject();
		}
		handleCloseDialog();
	}, [dialogServiceState.cancelLabel, handleCloseDialog]);

	const {
		open,
		title,
		okLabel,
		cancelLabel,
		content,
		extraContent,
	} = dialogServiceState as DialogServiceState;

	return (
		<>
			<DialogContext.Provider value={openDialog}>
				{children}
			</DialogContext.Provider>
			<Modal
				isOpen={open}
				closable={false}
				shouldCloseOnEsc={false}
				shouldCloseOnOverlayClick={false}
				fullBox
				style={{
					content: {
						maxWidth: 412,
					},
				}}
			>
				<div
					style={{
						padding: "32px 40px",
					}}
				>
					{title && (
						<div className="u-mb-20">
							<Heading color="black" noBackground>
								{title}
							</Heading>
						</div>
					)}
					{content && (
						<div style={{ color: "#728095", marginTop: 12, marginBottom: 32 }}>
							<Text centered>{content}</Text>
						</div>
					)}
					{extraContent && (
						<div style={{ marginBottom: 32 }}>{extraContent}</div>
					)}
					<Row className="u-ml-negative-6 u-mr-negative-6 justify-content-center">
						{cancelLabel && (
							<Col sm="6" className="u-pr-6 u-pl-6 my-auto">
								<Button fullWidth onClick={handleCancel} modifiers="secondary">
									{cancelLabel}
								</Button>
							</Col>
						)}
						<Col sm="6" className="u-pr-6 u-pl-6 u-mt-16 u-mt-sm-0">
							<Button fullWidth onClick={handleOk}>
								{okLabel}
							</Button>
						</Col>
					</Row>
				</div>
			</Modal>
		</>
	);
};

export default DialogServiceProvider;
