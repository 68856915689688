import React from "react";

import { mapModifiers } from "helpers/component";

import { Icon } from "../Icon";

export interface Props {
	nonFixed?: boolean;
	extraClassName?: string;
}

export const Loading: React.FC<Props> = ({ nonFixed, extraClassName }) => {
	return (
		<div
			className={`${mapModifiers("a-loading", nonFixed && "nonfixed")}${
				extraClassName ? ` ${extraClassName}` : ""
			}`}
		>
			<Icon iconName="aqua-dots" />
		</div>
	);
};
