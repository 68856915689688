import React, { useCallback, useEffect, useRef, useState } from "react";

import jsQR from "jsqr";
import Webcam from "react-webcam";

import { Button } from "components/atoms/Button";
import { TextField } from "components/atoms/TextField";
import { toastSingleMode } from "components/atoms/Toastify";
import { useAsyncAction } from "hooks/useAsyncAction";
import { apiScanQR } from "services/PG";
import { useAppDispatch } from "store";
import { initialUserSessionAction } from "store/Auth";

interface ScanQrProps {
	handleSuccess?: () => void;
}

const ScanQr: React.FC<ScanQrProps> = ({ handleSuccess }) => {
	const dispatch = useAppDispatch();
	const webcamRef = useRef<Webcam>(null);
	const [loading, setLoading] = useState(false);
	const [permit, setPermit] = useState<PermissionState>("prompt");
	const [isInput, setIsInput] = useState(false);
	const [codeInput, setCodeInput] = useState("");
	const onPermit = (v: string) => {
		setPermit(v as PermissionState);
	};

	const errorFunc = (message: string) => {
		toastSingleMode({
			type: "error",
			description: message,
		});
	};

	const [senQRCode] = useAsyncAction(apiScanQR, {
		onSuccess: () => {
			if (handleSuccess) {
				dispatch(initialUserSessionAction());
				handleSuccess();
			}
			setLoading(false);
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		onFailed: (error: any) => {
			if (error?.response?.data?.errors?.[0]?.field === "short_code")
				errorFunc(`Mã không hợp lệ`);
			else errorFunc(`Đã xãy ra lỗi khi quét mã!`);
			setLoading(false);
		},
	});

	const submitCode = async () => {
		if (isInput) {
			setLoading(true);
			await senQRCode(undefined, codeInput);
		} else {
			setIsInput(true);
		}
	};

	const tick = useCallback(async (isLoading: boolean) => {
		if (webcamRef && webcamRef.current) {
			const canvas = webcamRef.current.getCanvas();
			if (canvas) {
				const ctx = canvas.getContext("2d");
				if (ctx) {
					const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
					if (imageData) {
						const code = jsQR(
							imageData.data,
							imageData.width,
							imageData.height,
							{
								inversionAttempts: "dontInvert",
							}
						);
						if (code && !isLoading) {
							setLoading(true);
							await senQRCode(code.data);
						}
					}
				}
			}
		}
		requestAnimationFrame(() => tick(isLoading));
	}, []);

	useEffect(() => {
		requestAnimationFrame(() => tick(loading));
	}, [loading, tick]);

	return (
		<div className="t-scanQr">
			<div className="t-scanQr_content">
				{isInput ? (
					<div className="t-scanQr_input">
						<TextField
							placeholder="Mã"
							value={codeInput}
							onChange={(e) => setCodeInput(e.currentTarget.value)}
						/>
					</div>
				) : (
					<div className="t-qrPopup">
						<div className="t-qrPopup_video">
							<div className="wrap">
								<Webcam
									ref={webcamRef}
									id="webcam"
									audio={false}
									onUserMediaError={() => onPermit("denied")}
									onUserMedia={() => onPermit("granted")}
									videoConstraints={{
										width: 224,
										height: 224,
										facingMode: { exact: "environment" },
									}}
								/>
								{permit === "denied" ? (
									<p>Không tìm thấy máy ảnh</p>
								) : (
									<p>
										Di chuyển mã đến trung tâm camera, mã sẽ được quét tự động
									</p>
								)}
							</div>
						</div>
					</div>
				)}
				<div className="t-scanQr_button">
					{isInput && (
						<Button
							onClick={() => {
								setIsInput(false);
								setCodeInput("");
							}}
							loading={loading}
						>
							Quay lại
						</Button>
					)}
					<Button onClick={submitCode} loading={loading}>
						{isInput ? "Tiếp tục" : "Hoặc nhập mã"}
					</Button>
				</div>
			</div>
		</div>
	);
};

export default ScanQr;
