import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { UserProfile } from "services/PG/types";
import { clearCustomerSession, clearLocalStorage } from "services/Sessions";

import { initialUserSessionAction } from "./thunks";

export interface AuthReducerState {
	status: "AUTH" | "UNAUTH";
	loading: boolean;
	userProfile?: UserProfile;
	accessToken?: string;
	scanner?: string;
}

const initialState: AuthReducerState = {
	status: "UNAUTH",
	loading: false,
	userProfile: undefined,
};

const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		logout: (state) => {
			state.loading = false;
			state.status = "UNAUTH";
			state.userProfile = undefined;
			clearCustomerSession();
			clearLocalStorage();
		},
		updateUserAuthState: (
			state,
			action: PayloadAction<{
				user: UserProfile;
				token: string;
			}>
		) => {
			state.status = "AUTH";
			state.userProfile = action.payload.user;
		},
		updateScanner: (state, action: PayloadAction<string>) => {
			state.scanner = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(initialUserSessionAction.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(initialUserSessionAction.fulfilled, (state, action) => {
			const { userProfile } = action.payload;
			state.loading = false;
			state.status = "AUTH";
			state.userProfile = userProfile;
			state.scanner = userProfile.scanner?.name;
		});
		builder.addCase(initialUserSessionAction.rejected, (state) => {
			state.loading = false;
			state.status = "UNAUTH";
			state.userProfile = undefined;
			clearCustomerSession();
		});
	},
});

export default authSlice;
