import React, { forwardRef } from "react";

import { mapModifiers } from "helpers/component";
import { ConnectForm, UseFormProps } from "helpers/form";

export interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
	errorMessage?: string;
}

export const TextField = forwardRef<HTMLInputElement, Props>(
	({ children, errorMessage, ...innerProps }, ref) => {
		return (
			<>
				<div className={mapModifiers("a-textfield", errorMessage && "error")}>
					<input
						{...innerProps}
						className="a-textfield_input"
						ref={ref}
						autoComplete="new-password"
					/>
				</div>
				{errorMessage && (
					<div className="a-textfield_errormessage">{errorMessage}</div>
				)}
			</>
		);
	}
);

TextField.displayName = "TextField";

interface TextfieldHookFormProps extends Props {
	name: string;
}

export const TextfieldHookForm: React.FC<TextfieldHookFormProps> = (props) => (
	<ConnectForm>
		{({ register, errors, control }: UseFormProps) => (
			<TextField
				ref={register}
				errorMessage={
					errors[props.name] ? errors[props.name].message : undefined
				}
				{...props}
			/>
		)}
	</ConnectForm>
);
