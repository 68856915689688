export const config = {
	tokenField: "ESW",
	saveQrCode: "HQR",
} as const;

export interface CustomerSessionPayload {
	accessToken: string;
}

export interface CustomerSessionInfo {
	accessToken: string;
}

export const initialCustomerSession = (payload: CustomerSessionPayload) => {
	const { accessToken } = payload;

	localStorage.setItem(config.tokenField, accessToken);
};

export const getCustomerSessionInfo = (): CustomerSessionInfo | false => {
	const accessToken = localStorage.getItem(config.tokenField);
	if (!accessToken) return false;
	return {
		accessToken,
	};
};

export const clearCustomerSession = () => {
	localStorage.removeItem(config.tokenField);
};

export const setLocalStorage = (name: string, value: string) => {
	window.localStorage.setItem(name, value);
};

export const getLocalStorage = (name: string): string | null =>
	window.localStorage.getItem(name);

export const clearLocalStorage = () => {
	window.localStorage.clear();
};
