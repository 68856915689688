import http from "services/http";

export const apiCustomerPrizesDraw = () =>
	http.post<BaseAPIResponse<{ prizeCode: string }>>(
		"/customers/prizes/draw",
		undefined,
		{
			requireAuthentication: true,
		}
	);
