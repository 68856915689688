import React from "react";

import { Checkbox } from "components/atoms/Checkbox";
import { Image } from "components/atoms/Image";
import { mapModifiers } from "helpers/component";

interface BillSelectProps extends React.InputHTMLAttributes<HTMLInputElement> {
	imgSrc: string;
	time?: string;
	isUsed?: boolean;
	handleSelectImage?: () => void;
}

const BillSelect: React.FC<BillSelectProps> = ({
	imgSrc,
	time,
	checked,
	isUsed,
	onChange,
	handleSelectImage,
	value,
	...props
}) => (
	<div className={mapModifiers("m-billSelect", isUsed && "used")}>
		<Image
			src={imgSrc}
			aspectRatio="2x3"
			alt={`billSelect-${value}`}
			onClick={handleSelectImage}
		/>
		<div style={{ textAlign: "center" }}>{time}</div>
		<div className="m-billSelect_radioWrapper">
			<Checkbox
				checked={checked}
				onChange={onChange}
				value={value}
				disabled={isUsed}
				{...props}
			/>
		</div>
	</div>
);

export default BillSelect;
