import React from "react";

export type Props = React.InputHTMLAttributes<HTMLInputElement>;

export const Radio: React.FC<Props> = ({ children, id, ...innerProps }) => (
	<div className="a-radio">
		<label className="a-radio_label" htmlFor={id as string}>
			<input {...innerProps} className="a-radio_input" id={id} type="radio" />
			<div className="a-radio_body">
				<div className="a-radio_inner" />
			</div>
			{!!children && <div className="a-radio_content">{children}</div>}
		</label>
	</div>
);
