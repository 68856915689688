/* eslint-disable react/button-has-type */
import React from "react";

import { Icon, IconName } from "components/atoms/Icon";
import { mapModifiers } from "helpers/component";

export type Modifier =
	| "otp"
	| "smallSize"
	| "mediumSize"
	| "textButton"
	| "yellowLinear"
	| "linearPrimary"
	| "secondary";

export interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	iconName?: IconName;
	loading?: boolean;
	modifiers?: Modifier | Modifier[];
	fullWidth?: boolean;
}

export const Button: React.FC<Props> = ({
	iconName,
	loading,
	children,
	modifiers,
	fullWidth,
	...props
}) => (
	<button
		{...props}
		className={mapModifiers("a-button", modifiers, fullWidth && "fullWidth")}
	>
		<div className="a-button_wrapper">
			{loading ? (
				<div className="a-button_loading">
					<Icon iconName="white-spinner" />
				</div>
			) : (
				<>
					{children}
					{iconName && <Icon iconName={iconName} />}
				</>
			)}
		</div>
	</button>
);

Button.defaultProps = {
	type: "button",
	disabled: false,
	loading: false,
};
