import { DialogServiceProvider, UseConfirm, UseAlert } from "contexts/Dialog";
import { AppNavigationProvider } from "contexts/Navigation";
import { AppInitializer } from "lib/appinitializer";
import Routes from "pages/routes";

import "./index.scss";

const App = () => (
	<AppNavigationProvider>
		<DialogServiceProvider>
			<AppInitializer />
			<Routes />
			<UseConfirm />
			<UseAlert />
		</DialogServiceProvider>
	</AppNavigationProvider>
);
export default App;
