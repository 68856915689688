import http from "services/http";

import { PhotoPostPayload } from "./types";

export const apiPostPhoto = ({
	// billImage,
	customerImage,
}: PhotoPostPayload) => {
	const formData = new FormData();

	// formData.append("bill_image", billImage);
	formData.append("customer_image", customerImage);

	return http.post("/customers/photos", formData, {
		headers: {
			"content-type": "multipart/form-data",
		},
		requireAuthentication: true,
	});
};
