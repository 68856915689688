/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { forwardRef, useEffect, useImperativeHandle, useRef } from "react";

export interface SelfieProcessProps {
	takeSelfie: () => string;
}

interface ProcessProps {
	handlePermission?: (val: PermissionState) => void;
}

const Processing = forwardRef<SelfieProcessProps, ProcessProps>(
	({ handlePermission }, ref) => {
		const videoRef = useRef<null | HTMLVideoElement>(null);

		useEffect(() => {
			let terminateWorker: () => void;
			let stopCamera: () => void;

			(async () => {
				try {
					const mediaStream = await navigator.mediaDevices.getUserMedia({
						audio: false,
						video: {
							facingMode: {
								exact: "environment",
							},
							aspectRatio: 4 / 3,
						},
					});

					stopCamera = () => {
						mediaStream.getTracks().forEach((t) => t.stop());
					};
					if (!videoRef.current) {
						return;
					}
					videoRef.current.srcObject = mediaStream;
					if (handlePermission) handlePermission("granted");
				} catch (error) {
					if (handlePermission) handlePermission("denied");
				}
			})();

			return () => {
				if (terminateWorker) {
					terminateWorker();
				}
				if (stopCamera) {
					stopCamera();
				}
			};
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

		useImperativeHandle(ref, () => ({
			takeSelfie: (): string => {
				const cameraEl = videoRef.current;
				if (!cameraEl) {
					return "";
				}

				const canvas = document.createElement("canvas");
				const cameraWidth = cameraEl.videoWidth;
				const cameraHeight = cameraEl.videoHeight;
				const maxWidth = cameraWidth > cameraHeight ? 1280 : 960;
				const maxHeight = cameraWidth > cameraHeight ? 960 : 1280;
				// const ratio = cameraEl.clientWidth / cameraEl.clientHeight;
				let ratio = Math.min(maxWidth / cameraWidth, maxHeight / cameraHeight);
				if (ratio > 1) {
					ratio = 1;
				}

				const canvasWidth = cameraWidth * ratio;
				const canvasHeight = cameraHeight * ratio;

				canvas.width = canvasWidth;
				canvas.height = canvasHeight;

				const ctx = canvas.getContext("2d");

				ctx?.drawImage(
					cameraEl,
					0.5 * (canvasWidth - cameraWidth),
					0.5 * (canvasHeight - cameraHeight)
				);

				return canvas.toDataURL("image/jpeg");
			},
		}));

		return <video autoPlay playsInline muted ref={videoRef} />;
	}
);

export default Processing;
