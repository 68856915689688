/* eslint-disable react/forbid-elements */
import { Suspense, useEffect } from "react";

import { Route, Switch, useHistory, Redirect } from "react-router-dom";

import { Loading } from "components/atoms/Loading";
import { MainLayout } from "components/templates/MainLayout";
import PATHS from "constants/paths";
import { PrivateRoute, UnAuthRoute } from "helpers/authenticationRoute";
import { BasePageProps } from "helpers/component";
import useInAppDetect from "hooks/useInAppDetect";
import LoginPage from "pages/Login";
import LogoutPage from "pages/Logout";
import { useAppSelector } from "store";

import Home from "./Home";

const AuthRoutes: React.FC<BasePageProps> = () => {
	return (
		<Suspense fallback={null}>
			<Switch>
				<PrivateRoute path={PATHS.APP} exact component={Home} />
				<PrivateRoute path={PATHS.LOGOUT} exact component={LogoutPage} />
			</Switch>
		</Suspense>
	);
};

function Routes() {
	const history = useHistory();
	const inApp = useInAppDetect();
	const { loading } = useAppSelector((state) => state.auth);
	const { type } = useAppSelector((state) => state.stores);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [history.location.pathname]);

	if (inApp) {
		return inApp === "ios" ? (
			<span>
				Vui lòng nhấn và giữ <a href={window.location.href}>liên kết này</a>{" "}
				trong 02 giây để chuyển đổi trình duyệt phù hợp và trải nghiệm
			</span>
		) : (
			<span>
				Vui lòng nhấn vào{" "}
				<a
					href={`intent://${window.location.host}${window.location.pathname}${
						window.location.search
					}#Intent;scheme=${window.location.protocol.replace(
						/\W/g,
						""
					)};action=android.intent.action.VIEW;end;`}
				>
					liên kết này
				</a>{" "}
				để chuyển đổi trình duyệt phù hợp và trải nghiệm.
			</span>
		);
	}

	if (loading) return <Loading />;

	return (
		<MainLayout modifiers={type === "glucerna" ? type : "default"}>
			<Suspense fallback={null}>
				<Switch>
					<Route
						path={[PATHS.APP, PATHS.LOGOUT]}
						exact
						component={AuthRoutes}
					/>
					<UnAuthRoute path={PATHS.LOGIN} exact component={LoginPage} />
					<Route path="*" exact render={() => <Redirect to={PATHS.APP} />} />
				</Switch>
			</Suspense>
		</MainLayout>
	);
}
export default Routes;
