import React from "react";

import { Route, Redirect, RouteProps } from "react-router-dom";

import PATHS from "constants/paths";
import { useAppSelector } from "store";

interface PrivateRouteProps extends RouteProps {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	component: React.FunctionComponent<any>;
	extraProps?: unknown;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({
	component: Component,
	extraProps,
	...rest
}) => {
	const status = useAppSelector((state) => state.auth.status);

	return (
		<Route
			{...rest}
			render={(props) => {
				if (status === "UNAUTH") {
					return (
						<Redirect
							to={{
								pathname: PATHS.LOGIN,
							}}
						/>
					);
				}

				return <Component {...props} {...extraProps} />;
			}}
		/>
	);
};

export const UnAuthRoute: React.FC<PrivateRouteProps> = ({
	component: Component,
	extraProps,
	...rest
}) => {
	const status = useAppSelector((state) => state.auth.status);

	return (
		<Route
			{...rest}
			render={(props) => {
				if (status === "AUTH")
					return (
						<Redirect
							to={{
								pathname: PATHS.CUSTOMERINFO,
							}}
						/>
					);

				return <Component {...props} {...extraProps} />;
			}}
		/>
	);
};
